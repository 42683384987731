<div
  class="bb-input-datepicker__wrapper"
  [ngClass]="{
    'ng-invalid': parentFormControl?.invalid,
    'ng-touched': parentFormControl?.touched,
    'bb-input-datepicker__range-split-wrapper': rangeSelectionSplit
  }"
>
  @if (label) {
    <label class="bb-input-datepicker__label form-label" for="{{ id }}">{{ label }}</label>
  }
  @if (rangeSelectionSplit && labelTo) {
    <label class="bb-input-datepicker__label form-label" for="to_{{ id }}">{{ labelTo }}</label>
  }

  <div class="input-group bb-input-datepicker" #positionTarget>
    @if (!rangeSelection) {
      <input
        type="text"
        id="{{ id }}"
        name="date"
        class="form-control bb-input-datepicker__input"
        [attr.placeholder]="placeholder"
        [attr.aria-labelledby]="ariaLabelledby"
        [attr.aria-describedby]="datepickerDescribedby"
        [attr.aria-expanded]="ariaExpanded"
        [attr.role]="role"
        [attr.size]="size"
        [attr.aria-invalid]="ariaInvalid"
        [attr.aria-owns]="ariaOwns"
        [attr.aria-activedescendant]="ariaActivedescendant"
        [attr.aria-label]="(!label && ariaLabel) || null"
        ngbDatepicker
        [container]="container"
        [minDate]="_minDate"
        [maxDate]="_maxDate"
        [markDisabled]="markDisabled"
        [readOnly]="readonly"
        [required]="required"
        [navigation]="navigation"
        [dayTemplate]="rangeSelectionSplit ? rangeDay : singleDay"
        [displayMonths]="displayMonths"
        [formControl]="dateInput"
        [placement]="placement"
        [positionTarget]="positionTarget"
        [restoreFocus]="!rangeSelectionSplit"
        (click)="onInputFocus(clickOpen)"
        (focus)="onInputFocus(focusOpen)"
        (blur)="onControlBlur($event)"
        (input)="rangeSelectionSplit ? onInputChangeSplitRange(datePickerInput) : onInputChange(datePickerInput)"
        (closed)="onClosed()"
        #datePicker="ngbDatepicker"
        #datePickerInput
        [attr.data-role]="'input-date-single'"
        [attr.autocomplete]="autocomplete"
        [firstDayOfWeek]="firstDayOfWeek"
        (dateSelect)="rangeSelectionSplit ? onRangeDateSelectSplit($event) : null"
        [autoClose]="!rangeSelectionSplit"
        (ngModelChange)="autoMask(datePickerInput); !rangeSelectionSplit ? onChange($event) : null"
        [maxlength]="maskLength"
        [footerTemplate]="footerTemplate"
      />
    } @else {
      <input
        type="text"
        name="date"
        id="{{ id }}"
        class="form-control bb-input-datepicker__range"
        [formControl]="dateInput"
        [readOnly]="readonly"
        [required]="required"
        #datePickerInputRange
        [attr.placeholder]="placeholder"
        [attr.aria-label]="(!label && ariaLabel) || null"
        [attr.aria-describedby]="datepickerDescribedby"
        [attr.aria-labelledby]="ariaLabelledby"
        [attr.aria-expanded]="ariaExpanded"
        [attr.role]="role"
        [attr.size]="size"
        [attr.aria-invalid]="ariaInvalid"
        [attr.aria-owns]="ariaOwns"
        [attr.aria-activedescendant]="ariaActivedescendant"
        (click)="onInputFocus(clickOpen)"
        (focus)="onInputFocus(focusOpen)"
        (blur)="onControlBlur($event)"
        (input)="onInputChangeRange(datePickerInputRange)"
        [attr.data-role]="'input-date-range'"
        (ngModelChange)="autoMask(datePickerInputRange)"
        [maxlength]="maskLength"
      />
      <input
        type="text"
        class="datepicker-input-hidden bb-input-datepicker__range-input-hidden"
        ngbDatepicker
        (closed)="onClosed()"
        [minDate]="_minDate"
        [maxDate]="_maxDate"
        [markDisabled]="markDisabled"
        [autoClose]="false"
        [attr.aria-label]="label || ariaLabel || null"
        [attr.aria-describedby]="datepickerDescribedby"
        [attr.aria-labelledby]="ariaLabelledby"
        [attr.aria-expanded]="ariaExpanded"
        [attr.role]="role"
        [attr.size]="size"
        [attr.aria-invalid]="ariaInvalid"
        [attr.aria-owns]="ariaOwns"
        [attr.aria-activedescendant]="ariaActivedescendant"
        [readOnly]="readonly"
        [required]="required"
        [firstDayOfWeek]="firstDayOfWeek"
        [positionTarget]="positionTarget"
        [placement]="placement"
        #datePicker="ngbDatepicker"
        [dayTemplate]="rangeDay"
        navigation="arrows"
        [attr.data-role]="'input-datepicker'"
        (dateSelect)="onRangeDateSelect($event)"
        [container]="container"
        [footerTemplate]="footerTemplate"
        [formControl]="dateInputRange"
      />
    }
    @if (datepickerDescribedby === defaultDatepickerDescribedby) {
      <div class="visually-hidden" aria-live="polite" [id]="defaultDatepickerDescribedby">
        @if (hoveredDate$ | async; as hoveredDate) {
          @if (hoveredDate.isSelecting) {
            <span i18n="Default selecting date state i18n message@@defaultSelectingState.with.i18n.support"
              >Selecting date:
              {{
                hoveredDate.date?.year + '-' + hoveredDate.date?.month + '-' + hoveredDate.date?.day | date: 'longDate'
              }}</span
            >
          } @else {
            @if (hoveredDate.date) {
              <ng-container i18n="Default selected date state i18n message@@defaultSelectedState.with.i18n.support">
                Selected date is
                {{
                  hoveredDate.date.year + '-' + hoveredDate.date.month + '-' + hoveredDate.date.day | date: 'longDate'
                }}.
              </ng-container>
            }
          }
        }
      </div>
    }
    <button
      #datePickerButton
      class="btn bb-input-datepicker__calendar-opener-button"
      [class]="btnColorClass"
      [disabled]="disabled || readonly"
      (blur)="onControlBlur($event)"
      [attr.aria-label]="toggleCalendarAriaLabel()"
      (click)="onPickerTogglerClick($event)"
      [attr.data-role]="'toggle-calendar-button'"
    >
      <bb-icon-ui [color]="iconColor" [name]="icon" [size]="iconSize"></bb-icon-ui>
    </button>
  </div>

  @if (rangeSelectionSplit) {
    <div class="input-group bb-input-datepicker">
      <input
        type="text"
        id="to_{{ id }}"
        name="dateTo"
        class="form-control bb-input-datepicker__input"
        [attr.placeholder]="placeholder"
        [attr.aria-labelledby]="ariaLabelledby"
        [attr.aria-describedby]="datepickerDescribedby"
        [attr.aria-expanded]="ariaExpanded"
        [attr.role]="role"
        [attr.size]="size"
        [attr.aria-invalid]="ariaInvalid"
        [attr.aria-owns]="ariaOwns"
        [attr.aria-activedescendant]="ariaActivedescendant"
        [attr.aria-label]="(!labelTo && (ariaLabelToDate || (!label && ariaLabel))) || null"
        ngbDatepicker
        [minDate]="_minDate"
        [maxDate]="_maxDate"
        [markDisabled]="markDisabled"
        [readOnly]="readonly"
        [required]="required"
        [displayMonths]="displayMonths"
        [navigation]="navigation"
        [restoreFocus]="false"
        [dayTemplate]="rangeDay"
        [dayTemplateData]="rangeTemplateData"
        [formControl]="dateInputTo"
        [placement]="placement"
        (click)="onInputFocusTo(clickOpen)"
        (focus)="onInputFocusTo(focusOpen)"
        (blur)="onControlBlur($event)"
        (input)="rangeSelectionSplit ? onInputChangeSplitRangeTo(datePickerInputTo) : onInputChange(datePickerInputTo)"
        (closed)="onClosedTo()"
        #datePickerTo="ngbDatepicker"
        #datePickerInputTo
        [attr.data-role]="'input-date-single'"
        [attr.autocomplete]="autocomplete"
        [firstDayOfWeek]="firstDayOfWeek"
        (dateSelect)="rangeSelectionSplit ? onRangeDateSelectSplitTo($event) : null"
        [autoClose]="!rangeSelectionSplit"
        (ngModelChange)="autoMask(datePickerInputTo)"
        [footerTemplate]="footerTemplate"
      />
      <button
        #datePickerButtonTo
        class="btn bb-input-datepicker__calendar-opener-button"
        [class]="btnColorClass"
        [disabled]="disabled || readonly"
        [attr.aria-label]="toggleCalendarAriaLabel(true)"
        (blur)="onControlBlur($event)"
        (click)="onPickerTogglerClickTo($event)"
        [attr.data-role]="'toggle-calendar-button'"
      >
        <bb-icon-ui [color]="iconColor" [name]="icon" [size]="iconSize"></bb-icon-ui>
      </button>
    </div>
  }
</div>

<!-- Single input date range template -->

<!-- template end -->

<!-- Single Date day template -->
<ng-template
  #singleDay
  let-date="date"
  let-disabled="disabled"
  let-selected="selected"
  let-currentMonth="currentMonth"
  let-data="data"
>
  <bb-input-datepicker-single-day-template-ui
    [disabled]="disabled"
    [selected]="selected"
    [currentMonth]="currentMonth"
    [date]="date"
    (hovered)="onDayHover($event)"
    [hoveredDate]="data?.isTo ? pickerHoveredDayDateTo : hoveredDate$.getValue().date"
  >
  </bb-input-datepicker-single-day-template-ui>
</ng-template>

<!-- template end -->

<!-- Date range day template -->
<ng-template
  #rangeDay
  let-date="date"
  let-disabled="disabled"
  let-selected="selected"
  let-currentMonth="currentMonth"
  let-data="data"
>
  <bb-input-datepicker-range-day-template-ui
    [date]="date"
    [fromDate]="fromDate$.getValue()"
    [toDate]="toDate$.getValue()"
    [splitRange]="rangeSelectionSplit"
    [toDateDatepicker]="data?.isTo"
    [disabled]="disabled"
    [currentMonth]="currentMonth"
    (hovered)="onDayHover($event)"
    [hoveredDate]="data?.isTo ? pickerHoveredDayDateTo : hoveredDate$.getValue().date"
  >
  </bb-input-datepicker-range-day-template-ui>
</ng-template>
